import axios from "@/libs/axios";

const state = {
  address: null,
  service: null,
  checkoutData: {
    category: null,
    manufacturer: null,
    manufacturer_model: null,
    device_category: null,
    repair_option_one: null,
    repair_option_two: null,
    price: 0,
    gender: 'Frau',
    insurance: false,
    firm: '',
    firstname: '',
    name: '',
    postal: '',
    city: '',
    street: '',
    phone: '',
    email: '',
    delivery_address: {
      firm: '',
      selected: 0,
      firstname: '',
      name: '',
      postal: '',
      city: '',
      street: '',
    },
    shipping_option: 'Versand mit DHL',
    imei: '',
    model: '',
    screen_lock_code: '',
    guarantee_claim: '',
    repair_approval: false,
    repair_approval_additional_costs: 0,
    repair_approval_price: 0,
    terms_and_conditions: false,
    problem_description: ''
  },
  loading: {
    categories: false,
    manufacturers: false,
    manufacturer_models: false,
    repair_options: false,
  },
  products: [],
  categories: [],
  manufacturers: [],
  manufacturer_models: [],
  repair_options: [],
  contactData: {
    firstname: '',
    name: '',
    model: '',
    error:  '',
    message:  '',
    phone:  '',
    email:  ''
  }
}

const mutations = {
  SET_ADDRESS: (state, address) => {
    state.address = address;
  },
  SET_SERVICE: (state, service) => {
    state.service = service;
  },
  SET_CHECKOUT_DATA: (state, checkoutData) => {
    state.checkoutData = checkoutData;
  },
  SET_PRODUCTS: (state, products) => {
    state.products = products;
  },
  SET_CATEGORIES: (state, categories) => {
    state.categories = categories;
  },
  SET_MANUFACTURERS: (state, manufacturers) => {
    state.manufacturers = manufacturers;
  },
  SET_MANUFACTURER_MODELS: (state, models) => {
    state.manufacturer_models = models;
  },
  SET_REPAIR_OPTIONS: (state, repair_options) => {
    state.repair_options = repair_options;
  },
  SET_LOADING_STATE_CATEGORIES: (state, value) => {
    state.loading.categories = value;
  },
  SET_LOADING_STATE_MANUFACTURERS: (state, value) => {
    state.loading.manufacturers = value;
  },
  SET_LOADING_STATE_MANUFACTURER_MODELS: (state, value) => {
    state.loading.manufacturer_models = value;
  },
  SET_LOADING_STATE_REPAIR_OPTIONS: (state, value) => {
    state.loading.repair_options = value;
  },
  SET_PRICE: (state, price) => {
    state.checkoutData.price = price
  },
  SET_REPAIR_APPROVAL_PRICE: (state, price) => {
    state.checkoutData.repair_approval_price = price
  },
}

const actions = {
  GET_CATEGORIES: (context) => {
    context.commit('SET_LOADING_STATE_CATEGORIES', true);
    context.commit('SET_MANUFACTURERS', []);
    context.commit('SET_MANUFACTURER_MODELS', []);
    context.commit('SET_REPAIR_OPTIONS', []);
    context.commit('SET_PRICE', 0);

    axios.get(process.env.VUE_APP_ROOT_API + 'categories')
        .then(response => {
          response.data.push("Sonstiges")
          context.commit('SET_CATEGORIES', response.data)

          context.commit('SET_LOADING_STATE_CATEGORIES', true);
        })
  },
  GET_MANUFACTURERS: (context) => {
    context.commit('SET_LOADING_STATE_MANUFACTURERS', true);
    context.commit('SET_MANUFACTURERS', []);
    context.commit('SET_MANUFACTURER_MODELS', []);
    context.commit('SET_REPAIR_OPTIONS', []);
    context.commit('SET_PRICE', 0);

    context.state.checkoutData.manufacturer = null
    context.state.checkoutData.manufacturer_model = null
    context.state.checkoutData.repair_option_one = null
    context.state.checkoutData.repair_option_two = null

    let params = {
      params: {
        category: context.state.checkoutData.category
      }
    }

    axios.get(process.env.VUE_APP_ROOT_API + 'manufacturers', params).then(response => {
      context.commit('SET_MANUFACTURERS', response.data);
      context.commit('SET_LOADING_STATE_MANUFACTURERS', false);
    })
  },
  GET_MODELS: (context) => {
    context.commit('SET_LOADING_STATE_MANUFACTURER_MODELS', true);
    context.commit('SET_MANUFACTURER_MODELS', []);
    context.commit('SET_REPAIR_OPTIONS', []);
    context.commit('SET_PRICE', 0);

    context.state.checkoutData.manufacturer_model = null
    context.state.checkoutData.repair_option_one = null
    context.state.checkoutData.repair_option_two = null

    let params = {
      params: {
        category: context.state.checkoutData.category,
        manufacturer: context.state.checkoutData.manufacturer
      }
    }

    axios.get(process.env.VUE_APP_ROOT_API + 'manufacturers/models', params).then(response => {
      context.commit('SET_MANUFACTURER_MODELS', response.data);
      context.commit('SET_LOADING_STATE_MANUFACTURER_MODELS', false);
    })
  },
  GET_REPAIR_OPTIONS: (context) => {
    context.commit('SET_LOADING_STATE_REPAIR_OPTIONS', true);
    context.commit('SET_REPAIR_OPTIONS', []);
    context.commit('SET_PRICE', 0);

    context.state.checkoutData.repair_option_one = null
    context.state.checkoutData.repair_option_two = null

    let params = {
      params: {
        model: context.state.checkoutData.manufacturer_model,
      }
    }

    axios.get(process.env.VUE_APP_ROOT_API + 'manufacturers/models/repairoptions', params).then(response => {
      context.commit('SET_REPAIR_OPTIONS', response.data);
      context.commit('SET_LOADING_STATE_REPAIR_OPTIONS', false);
    })
  },
  CALCULATE_PRICE: (context) => {
    let price = 0

    if (context.state.checkoutData.repair_option_one) {
      if (context.state.checkoutData.repair_option_one['Brutto Preis'].trim() !== "") {
        price += parseFloat(context.state.checkoutData.repair_option_one['Brutto Preis'])
      }
    }

    if (context.state.checkoutData.repair_option_two) {
      if (context.state.checkoutData.repair_option_two['Kombi-Preis'].trim() !== "") {
        price += parseFloat(context.state.checkoutData.repair_option_two['Kombi-Preis'])
      }
    }

    let formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    price = formatter.format(price);

    context.commit('SET_PRICE', price);
  },
  CALCULATE_REPAIR_APPROVAL_PRICE: (context) => {
    let price = 0

    if (context.state.checkoutData.repair_option_one) {
      if (context.state.checkoutData.repair_option_one['Brutto Preis'].trim() !== "") {
        price += parseFloat(context.state.checkoutData.repair_option_one['Brutto Preis'])
      }
    }

    if (context.state.checkoutData.repair_option_two) {
      if (context.state.checkoutData.repair_option_two['Kombi-Preis'].trim() !== "") {
        price += parseFloat(context.state.checkoutData.repair_option_two['Kombi-Preis'])
      }
    }

    if (context.state.checkoutData.repair_approval && context.state.checkoutData.repair_approval_additional_costs > 0) {
      price += parseFloat(context.state.checkoutData.repair_approval_additional_costs)
    }

    let formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    price = formatter.format(price);

    context.commit('SET_REPAIR_APPROVAL_PRICE', price);
  }
}

const getters = {
  ADDRESS(state) {
    return state.address;
  },
  SERVICE(state) {
    return state.service;
  },
  PRODUCTS(state) {
    return state.products;
  },
  CHECKOUT_DATA(state) {
    return state.checkoutData;
  },
  CONTACT_DATA(state) {
    return state.contactData;
  },
  CATEGORIES(state) {
    return state.categories;
  },
  MANUFACTURERS(state) {
    return state.manufacturers;
  },
  MANUFACTURER_MODELS(state) {
    return state.manufacturer_models;
  },
  REPAIR_OPTIONS(state) {
    return state.repair_options;
  },
  LOADING_OPTIONS(state) {
    return state.loading;
  },
  CURRENT_PRICE(state) {
    return state.checkoutData.price
  },
  REPAIR_APPROVAL_PRICE(state) {
    return state.checkoutData.repair_approval_price
  }
}

export default {
  state, getters, mutations, actions
}
