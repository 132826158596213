import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)

require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import { localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
localize("de", de);

//axios
import '@axios'

import App from './App.vue'
import router from './router'
import store from './store'

// require('@core/scss/core.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#order-app')
