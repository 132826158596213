<template>
  <b-card>
    <h2 class="mb-2 h4">1. Gerät</h2>
    <p>Bitte wählen Sie Ihr Gerät aus:</p>

    <b-overlay :show="!LOADING_OPTIONS.categories || CATEGORIES.length == 0" rounded="xs">
      <div class="device-categories-inactive" v-if="CATEGORIES">
        <b-row class="d-none d-md-flex" v-if="false">
          <b-col
              v-for="(category, index) in CATEGORIES" :key="index"
              class="cursor-pointer productsearch-device-category"
              :class="[
                    'category-' + category.toLowerCase().replaceAll('/', '-'),
                    {
                      'col-auto': getImgUrl(category)
                    }
                ]"
              @click="selectDeviceCategory(category)"
          >
            <div class="category-content"
                 :class="{'bg-light': CHECKOUT_DATA.category == category}">
              <span v-if="category == 'Sonstiges'" class="title text-center mb-3 font-weight-bolder">
                Mein Gerät ist nicht vorhanden
              </span>
              <img class="img-fluid" v-if="getImgUrl(category)" :src="getImgUrl(category)" alt="">
              <span>
                {{ category }}
              </span>
            </div>
          </b-col>
        </b-row>

        <v-select
            :disabled="LOADING_OPTIONS.categories.length == 0"
            input-id="productsearch-device-catgeory"
            v-model="CHECKOUT_DATA.category"
            :options="CATEGORIES"
            placeholder="Ihr Gerät"
            size="sm"
            class="d-md-none-inactive"
            @input="updateManufacturers"
        />
      </div>
    </b-overlay>

    <template v-if="!showContactForm">
      <b-row class="mt-2">
        <b-col md="6">
          <h2 class="mb-2 h4">2. Marke</h2>
          <b-form-group label="Bitte wählen Sie Ihre Marke aus:" label-for="productsearch-manufacturer">
            <b-overlay :show="LOADING_OPTIONS.manufacturers" rounded="sm" spinner-small>
              <v-select
                  :disabled="MANUFACTURERS.length === 0"
                  input-id="productsearch-manufacturer"
                  v-model="CHECKOUT_DATA.manufacturer"
                  :options="MANUFACTURERS"
                  placeholder="Ihre Marke"
                  size="sm"
                  @input="selectManufacturer"
              />
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <h2 class="mb-2 h4">3. Modell</h2>
          <b-form-group label="Bitte wählen Sie Ihr Modell aus:" label-for="productsearch-model">
            <b-overlay :show="LOADING_OPTIONS.manufacturer_models" rounded="sm" spinner-small>
              <v-select
                  :disabled="MANUFACTURER_MODELS.length === 0"
                  input-id="productsearch-model"
                  v-model="CHECKOUT_DATA.manufacturer_model"
                  :options="MANUFACTURER_MODELS"
                  placeholder="Ihr Modell"
                  @input="selectModel"
              />
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>

      <h2 class="mt-2 mb-2 h4">4. Reparatur</h2>
      <b-row>
        <b-col md="6">
          <b-form-group label="Was muss repariert werden?" label-for="productsearch-repairoption-one">
            <b-overlay :show="LOADING_OPTIONS.repair_options" rounded="sm" spinner-small>
              <v-select
                  :disabled="REPAIR_OPTIONS.length == 0"
                  input-id="productsearch-repairoption-one"
                  v-model="CHECKOUT_DATA.repair_option_one"
                  :options="REPAIR_OPTIONS"
                  placeholder="Was muss repariert werden?"
                  size="sm"
                  @input="updatePrice"
                  label="Reparatur"
              >
                <template slot="option" slot-scope="option">
                  <span :class="option.icon"></span>
                  {{ option.Reparatur }} (<span
                    v-if="option['ab Preis'] === '1'">ab </span>{{ formatPrice(option['Brutto Preis']) }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  <span :class="option.icon"></span>
                  {{ option.Reparatur }} (<span
                    v-if="option['ab Preis'] === '1'">ab&nbsp;</span>{{ formatPrice(option['Brutto Preis']) }})
                </template>
              </v-select>
            </b-overlay>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="+ weitere Reparatur" label-for="productsearch-repairoption-two">
            <b-overlay :show="LOADING_OPTIONS.repair_options" rounded="sm" spinner-small>
              <v-select
                  :disabled="REPAIR_OPTIONS.length == 0 || !CHECKOUT_DATA.repair_option_one"
                  input-id="productsearch-repairoption-two"
                  v-model="CHECKOUT_DATA.repair_option_two"
                  :options="REPAIR_OPTIONS"
                  placeholder="Was muss repariert werden?"
                  size="sm"
                  label="Reparatur"
                  @input="updatePrice"
              >
                <template slot="option" slot-scope="option">
                  <span :class="option.icon"></span>
                  {{ option.Reparatur }} (<span
                    v-if="option['ab Preis'] === '1'">ab </span>{{ formatPrice(option['Kombi-Preis']) }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  <span :class="option.icon"></span>
                  {{ option.Reparatur }} (<span
                    v-if="option['ab Preis'] === '1'">ab&nbsp;</span>{{ formatPrice(option['Kombi-Preis']) }})
                </template>
              </v-select>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>

      <hr>
      <h2 class="h4">Ihr Auftrag:</h2>
      <ul class="list-unstyled mt-3">
        <li><strong>Gerät:</strong> {{ CHECKOUT_DATA.manufacturer_model }}</li>
        <li>
          <strong>Reparatur: </strong>
          <span v-if="CHECKOUT_DATA.repair_option_one">
          {{ (CHECKOUT_DATA.repair_option_one ? CHECKOUT_DATA.repair_option_one.Reparatur : '') }}

          <template v-if="CHECKOUT_DATA.repair_option_one['Brutto Preis'].trim() !== ''">
            (<span v-if="CHECKOUT_DATA.repair_option_one['ab Preis'] === '1'">ab&nbsp;</span>{{
              formatPrice(CHECKOUT_DATA.repair_option_one['Brutto Preis'])
            }})
          </template>
        </span>
          <span v-if="CHECKOUT_DATA.repair_option_two">,
          {{
              (CHECKOUT_DATA.repair_option_two ? CHECKOUT_DATA.repair_option_two.Reparatur : '')
            }}
          <template v-if="CHECKOUT_DATA.repair_option_two['Kombi-Preis'].trim() !== ''">
            (<span v-if="CHECKOUT_DATA.repair_option_two['ab Preis'] === '1'">ab&nbsp;</span>{{
              formatPrice(CHECKOUT_DATA.repair_option_two['Kombi-Preis'])
            }})
          </template>
        </span>
        </li>

        <p class="mt-2 mb-0">
          <strong>
            <span
                v-if="(CHECKOUT_DATA.repair_option_one && CHECKOUT_DATA.repair_option_one['ab Preis'] === '1') || (CHECKOUT_DATA.repair_option_two && CHECKOUT_DATA.repair_option_two['ab Preis'] === '1')">ab</span>
            {{ (CURRENT_PRICE == 0 ? '0,00€' : CURRENT_PRICE) }}
          </strong>
        </p>
        <p>
          Enthält 19% MwSt.
        </p>
      </ul>
      <b-row class="actions mt-3">
        <b-col md="6" class="offset-md-6">
          <b-button
              :disabled="!CHECKOUT_DATA.repair_option_one && !CHECKOUT_DATA.repair_option_two"
              class="btn-block"
              variant="primary"
              @click="goToCheckout"
          >
            Weiter
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <contact-component/>
    </template>
  </b-card>
</template>
<script>
import {BRow, BCol, BOverlay, BButton, BFormGroup, BCard} from 'bootstrap-vue';
import vSelect from 'vue-select'
import {mapActions, mapGetters} from 'vuex'
import ContactComponent from "@/views/ContactComponent";

export default {
  components: {
    ContactComponent,
    vSelect,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BFormGroup,
    BCard
  },
  data() {
    return {
      showContactForm: false,
      currentStep: 0,
      category: null,
      selectedDeviceCategory: null,
      selectedManufacturer: null,
      selectedModel: null,
      selectedRepairOptionOne: null,
      selectedRepairOptionTwo: null,
      categories: [],
      manufacturers: [],
      models: [],
      products: [],
      repairOptions: [],
      manufacturersIsLoading: false,
      modelsIsLoading: false,
      productsIsLoading: false,
      repairOptionsIsLoading: false,
      price: 0,
    }
  },
  computed: {
    ...mapGetters([
      'CATEGORIES',
      'MANUFACTURERS',
      'MANUFACTURER_MODELS',
      'REPAIR_OPTIONS',
      'LOADING_OPTIONS',
      'CHECKOUT_DATA',
      'CURRENT_PRICE'
    ]),
  },
  mounted() {
    if (this.CATEGORIES.length === 0) {
      this.GET_CATEGORIES()
    }
  },
  methods: {
    ...mapActions([
      'GET_CATEGORIES',
      'GET_MANUFACTURERS',
      'GET_MODELS',
      'GET_REPAIR_OPTIONS',
      'CALCULATE_PRICE'
    ]),
    getRepairOptionLabel(item) {
      return item['Brutto Preis']
    },
    updateManufacturers() {
      if (this.CHECKOUT_DATA.category == 'Sonstiges') {
        this.showContactForm = true
      } else {
        this.showContactForm = false
        this.GET_MANUFACTURERS()
      }
    },
    selectDeviceCategory(category) {
      if (category == 'Sonstiges') {
        this.showContactForm = true
        this.CHECKOUT_DATA.category = category
      } else {
        this.showContactForm = false
        this.CHECKOUT_DATA.category = category
        this.GET_MANUFACTURERS()
      }
    },
    selectManufacturer(manufacturer) {
      this.CHECKOUT_DATA.manufacturer = manufacturer
      this.GET_MODELS()
    },
    selectModel(model) {
      this.selectedModel = model
      this.GET_REPAIR_OPTIONS()
    },
    updatePrice() {
      this.CALCULATE_PRICE()
    },
    formatPrice(price) {
      let formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })

      return formatter.format(price)
    },
    getImgUrl(image) {
      let images = require.context('@/assets/images', false, /\.png$/)

      let loadedImage = null
      try {
        image = image.replaceAll("/", "-")
        loadedImage = images('./' + image + ".png")
      } catch (e) {
      }

      return loadedImage
    },
    goToCheckout() {
      this.$router.push({name: 'Checkout'})
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.productsearch-device-category {
}

.device-categories {
  min-height: 50px;

  @media(min-width: 768px) {
    min-height: 200px;
  }
}

.category-content {
  padding: 15px 10%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  img {
    margin-bottom: 10px;
  }

  border-radius: 15px;

  &:hover {
    background: $light;
  }
}

.col {
  order: 99;

  &.productsearch-device-category {
    max-width: 200px;
  }

  &.category-macbook {
    max-width: 22%;
    order: 2;

    .category-content {
      padding-left: 3%;
      padding-right: 3%;
    }
  }


  &.category-laptop-computer {
    max-width: 22%;
    order: 2;

    .category-content {
      padding-left: 3%;
      padding-right: 3%;
    }
  }

  &.category-tablet {
    max-width: 11%;
    order: 1;
  }

  &.category-watch {
    max-width: 8%;
    order: 3;
  }

  &.category-konsole {
    max-width: 15%;
    order: 4;
  }

  &.category-smartphone {
    max-width: 8%;

    img {
      max-width: 80%;
    }

    order: 0;
  }
}
</style>
