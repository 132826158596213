import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '../views/HomeComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
  },
  {
    path: '/zur-kasse',
    name: 'Checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/checkout/CheckoutAddress.vue')
  },
  {
    path: '/zur-kasse/zusammenfassung',
    name: 'Checkout Overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/checkout/CheckoutSummary.vue')
  },
  {
    path: '/zur-kasse/komplett',
    name: 'Checkout Complete',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/checkout/CheckoutFinish.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (from.path === "/" && to.path === "/") {
      return {
        x: 0,
        y: 0
      };
    }

    let el = document.getElementById("order-app")
    let y = el.getBoundingClientRect().top + window.pageYOffset;

    return {
      x: 0,
      y: y
    };
  },
})

export default router
