<template>
  <div class="mt-3">
    <b-alert
        variant="success"
        :show="formSubmitted"

    >
      <div class="alert-body">
        <h4 class="text-success h5">
          <strong>Nachricht erfolgreich gesendet.</strong>
        </h4>
        <span>Vielen Dank für Ihre Nachricht. Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzten.</span>
      </div>
    </b-alert>
    <div v-if="!formSubmitted">
      <div>
        <hr class="border-secondary mb-3">
        <h2 class="mb-1 h4">Vielen Dank für Ihr Interesse</h2>
        <p>Bitte nehmen Sie Kontakt mit uns auf und wir prüfen individuell welche Reparatur wir Ihnen anbieten
          können.</p>

        <validation-observer ref="contactForm">
          <b-row class="mt-3">
            <b-col md="6">
              <b-form-group label="Vorname" label-for="contact-firstname" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Vorname"
                    rules="required"
                    vid="contact-firstname"
                >
                  <b-form-input
                      id="contact-firstname"
                      v-model="CONTACT_DATA.firstname"
                      placeholder="Bitte geben Sie Ihren Vornamen an."
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Name" label-for="contact-name" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                    vid="contact-name"
                >
                  <b-form-input
                      id="contact-name"
                      v-model="CONTACT_DATA.name"
                      placeholder="Bitte geben Sie Ihren Namen an."
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="E-Mail" label-for="contact-email" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="E-Mail"
                    rules="required|email"
                    vid="contact-email"
                >
                  <b-form-input
                      id="contact-email"
                      v-model="CONTACT_DATA.email"
                      placeholder="Bitte geben Sie eine E-Mail Adresse an."
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Telefon" label-for="contact-phone" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Telefonnummer"
                    rules="required|numeric"
                    vid="contact-phone"
                >
                  <b-form-input
                      id="contact-email"
                      v-model="CONTACT_DATA.phone"
                      placeholder="Telefonnummer für Rückfragen"
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Ihr Gerät" label-for="contact-model" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Ihr Gerät"
                    rules="required"
                    vid="contact-model"
                >
                  <b-form-input
                      id="address-email"
                      v-model="CONTACT_DATA.model"
                      placeholder="Ihr Gerät"
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Wie zeigt sich der Fehler?" label-for="contact-error" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Fehler"
                    rules="required"
                    vid="contact-error"
                >
                  <b-form-input
                      id="contact-error"
                      v-model="CONTACT_DATA.error"
                      placeholder="Wie zeigt sich der Fehler?"
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Ihre Nachricht" label-for="contact-description" class="required">
                <validation-provider
                    #default="{ errors }"
                    name="Problembeschreibung"
                    rules="required"
                >
                  <b-form-textarea
                      id="contact-description"
                      v-model="CONTACT_DATA.message"
                      placeholder="Ihre Nachricht"
                  />
                  <small class="text-danger" :class="{'has-errors': errors.length > 0}">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <ValidationProvider
                  #default="{ errors }"
                  name="Datenschutzerklärung und AGB"
                  :rules="{ required: { allowFalse: false } }"
              >
                <b-form-checkbox
                    class=""
                    v-model="CONTACT_DATA.terms_and_conditions"
                >
                  Ich habe die <a class="font-weight-bold" href="https://handyreparatur-24.de/datenschutz/"
                                  target="_blank"
                                  title="Datenschutzerklärung">Datenschutzerklärung</a> zur Kenntnis genommen und
                  bestätige hiermit, dass ich den
                  <a class="font-weight-bold" href="https://handyreparatur-24.de/agb/" target="_blank"
                     title="Allgemeinen Geschäftsbedingungen">Allgemeinen Geschäftsbedingungen</a>
                  zustimme.
                </b-form-checkbox>

                <small v-if="errors.length > 0" class="text-danger" :class="{'has-errors': errors.length > 0}">
                  Bitte akzeptieren Sie die Datenschutzerklärung und die AGB.
                </small>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-button class="mt-2" variant="primary" @click="submitContactForm()" :disabled="transfer">
            <b-spinner v-if="transfer" small />
            Senden
          </b-button>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required, required_if, email, digits, numeric} from '@validations'

export default {
  name: "ContactComponent",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      transfer: false,
      formSubmitted: false
    }
  },
  computed: {
    ...mapGetters(['CONTACT_DATA']),
  },
  beforeMount() {
  },
  methods: {
    submitContactForm() {
      this.$refs.contactForm.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.transfer = true

              this.$http.put(process.env.VUE_APP_ROOT_API + 'contact/submit', this.CONTACT_DATA)
                  .then(response => {
                    this.transfer = false
                    this.formSubmitted = true
                    console.debug(response.data)
                  })
            }
          })
    }
  }
}
</script>

<style scoped>
.hr {
  border-color: #707070;
}
</style>
